import React, {useContext} from 'react';
// import AnchorLink from 'react-anchor-link-smooth-scroll';
import { ThemeContext } from 'providers/ThemeProvider';
import ToggleTheme from 'components/theme/Header/ToggleTheme';
import { Wrapper } from './styles';
import ToggleLang from "../ToggleLang";
import data from "../../../../data/Data";
import { Link } from "gatsby";

const NavbarLinks = ({ lang, desktop, notHomePage }) => {
  const { theme } = useContext(ThemeContext);
  const isEn = lang==="en";

  const  getLink = (lang, link) => {
    const langPath = lang==="en" ? "" : "/cn"
    return link.toLowerCase() !== 'home'
        ? (`${langPath}/${link.toLowerCase().replace(/ /g, "_")}/`)
        : (`${langPath}/`)
  }

  return (
    <Wrapper desktop={desktop} theme={theme}>
      <ToggleLang lang={lang}/>
      {notHomePage && <Link to={ isEn? "/" : "/cn/"}>{isEn? "Home" : "主页"}</Link>}
      {data.pageNames.map((link, idx) =>
          link.toLowerCase() !== 'home' &&
        <Link to={getLink(lang, link)} key={idx}>{ isEn ? link : data.pageNamesCN[idx] }</Link>
      )}
      <ToggleTheme />
    </Wrapper>
  )
};

export default NavbarLinks;
