import footerIllustration from 'assets/illustrations/footer.svg';
import styled from 'styled-components';

export const Wrapper = styled.div`
  padding: 10rem 0 4rem 0;
  // background-image: url(${footerIllustration});
  // background-size: cover;
  // background-position: top;
  // background-repeat: no-repeat;

  @media (max-width: 1960px) {
    padding: 5rem 0 4rem;
  }

  @media (max-width: 960px) {
    padding: 2rem 0;
  }
`;

export const Flex = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  
  & a{
    min-width: 30px;
  }

  @media (max-width: 680px) {
    flex-direction: column;
    text-align: center;
    align-items: center;
  }
`;

export const Links = styled.div`
  display: flex;
  align-items: center;

  a {
    margin: 0 0.5rem;

    img {
      margin: 0;
      display: inline-block;
      vertical-align: middle;
    }

    &:first-child,
    &:last-child {
      margin: 0;
    }
  }
`;

export const Details = styled.div`
  h2 {
    border: none;
  }
  p {
    margin: 0;
    //font-size: 15px;
  }
  //a:hover {
  //    text-decoration: underline;
  //}
  
  span {
    color: #212121;
  }

  @media (max-width: 680px) {
    margin-bottom: 2rem;
  }
`;
