import styled from 'styled-components';

export const Button = styled.button`
  cursor: pointer;
  border-radius: 2px;
  padding: 0.7rem 2.5rem !important;
  border: none;
  -webkit-appearance: none;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  color: #fff;
  //background: rgb(211, 177, 125);
  //background: rgb(235, 134, 35);
  //background: rgb(92, 179, 204);
  background: rgb(150, 125, 83);
  //background: rgba(80, 100, 90, 0.8);

  transition: background 0.2s;

  &:hover {
    //background: rgba(80, 100, 90, 1);
    background: rgba(150, 125, 83, 0.8);
    text-decoration: none;
    border: none;
  }

  &:focus {
    outline: none;
  }

  &:disabled {
    background: gray;
  }

  ${({ secondary }) =>
    secondary &&
    `
		background: #001F3F;
	`}
`;
