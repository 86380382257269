import styled from 'styled-components';

export const Wrapper = styled.div`
  ${({ desktop }) =>
    desktop
      ? `
			align-items: center;
			display: flex;

			@media (max-width: 970px) {
					display: none;
			}

			a {
					margin-right: 1rem;

					&:last-child {
							margin-right: unset;
					}
			}
		`
      : `
			padding: 3rem;
			display: flex;
			flex-direction: column;

			a {
					margin-bottom: 1rem;

					&:last-child {
							margin-bottom: unset;
					}
			}
	`}

  a {
	  font-family: "SF Pro Text Regular", sans-serif;
	  color: ${({ theme }) => (theme === 'light' ? '#000' : '#fff')};
	  text-decoration: none;

	  @media (max-width: 960px) {
		  color: ${({ theme }) => (theme === 'light' ? '#000' : '#fff')};
		  margin: 0.8em 0;
		  //white-space: nowrap;
	  }

	  @media (max-width: 1100px) and (min-width: 961px){
		  font-size: 15px;
		  margin-right: 0.8em;
	  }

	  @media (max-width: 1320px) and (min-width: 961px){
		  letter-spacing: -1px;
	  }
  }
`;
