module.exports = {
  defaultTitle: 'Tao Jiang',
  logo: 'https://raw.githubusercontent.com/biyunwu/portfolio-redesign/master/static/favicon/favicon-512.png',
  author: 'Biyun Wu',
  url: 'https://taojiangscholar.com',
  legalName: 'Tao Jiang',
  defaultDescription: 'Tao Jiang, author of Origins of Moral-Political Philosophy in Early China (Oxford 2021), Professor of Chinese Philosophy and Buddhist Philosophy at Rutgers University, New Brunswick',
  socialLinks: {
    twitter: 'https://twitter.com/taojiangscholar',
    github: '',
    linkedin: 'https://www.linkedin.com/in/tao-jiang-bb80b837/',
    instagram: '',
    youtube: '',
    google: '',
  },
  // googleAnalyticsID: 'UA-114112626-2', // old Google Analytics ID
  googleAnalyticsID: 'G-MS4HGP3RHH', // Google Analytics 4 ID
  googleOptimizeID: 'OPT-TV4FBZ2', // Google Optimize ID
  themeColor: '#fff',
  backgroundColor: '#666',
  social: {
    facebook: '',
    twitter: '@taojiangscholar',
  },
  address: {
    city: 'New Brunswick',
    region: 'New Jersey',
    country: 'United States',
    zipCode: '08901',
  },
  contact: {
    email: 'tjiang@rutgers.edu',
    phone: '',
  },
  foundingDate: '2018',
};
