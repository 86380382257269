import React, { useContext } from 'react';
import { ThemeContext } from 'providers/ThemeProvider';
import { Footer } from 'components/theme';
import { Global } from './styles';
import './fonts.css';
// import { Helmet } from "react-helmet";

export const Layout = ({ lang, children }) => {
    const { theme } = useContext(ThemeContext);

    return (
        <>
            {/* <Helmet>
                <meta name="theme-color" content={theme === 'light' ? "#fff" : "#000"} />
            </Helmet> */}
            {/* <meta name="theme-color" content={theme === 'light' ? "#fff" : "#000"} data-react-helmet="true" /> */}
            <Global theme={theme} />
            {children}
            <Footer lang={lang} />
        </>
    );
};
