const data = {
  pageNames: [
    'Home',
    'Origins of Chinese Phil',
    'Buddhism and Psychology',
    'Freud and China',
    'Papers',
    'Events',
    'Twitter',
    'About',
  ],
  pageNamesCN: ['主页', '先秦道德政治哲学起源', '佛教与心理学', '弗洛伊德与中国', '文章', '学术活动', '推特', '关于'],
  chinese_phil: {
    pageName: 'Origins of Chinese Phil',
    seoTitle: `Origins of Moral-Political Philosophy in Early China - Tao Jiang`,
    seoTitleCN: `先秦道德政治哲学的起源 - 蒋韬`,
    seoDescription: `Origins of Moral-Political Philosophy in Early China`,
    seoDescriptionCN: `先秦道德政治哲学的起源`,
    title: `Origins of Moral-Political Philosophy in Early China`,
    titleCN: `中国先秦道德政治哲学的起源：仁爱、正义与自由之论辩`,
    // sampleChapterLink: `https://academic.oup.com/book/39179/chapter/338651335`,
    coverImageName: `cover_chinese_phil.jpg`, // assets/images/cover_chinese_phil.jpg
    publisher: `Oxford University Press`,
    publisherCN: `牛津大学出版社`,
    publisherOrderLink: `https://global.oup.com/academic/product/origins-of-moral-political-philosophy-in-early-china-9780197611364`,
    promotionTextEn: `Paperback edition available. Get 30% off by using the code AAFLYG6.`,
    promotionTextZh: `平装版有售，使用折扣码 AAFLYG6 享七折优惠。`,
    bookLink: `https://www.amazon.com/Origins-Moral-Political-Philosophy-Early-China-dp-0197611362/dp/0197611362/`,
    themeColor: 'rgb(175, 149, 94)',
    awards: [
      {
        issuer: 'Association for Asian Studies',
        issuerCN: '美国亚洲研究学会',
        title: '>Honorable Mention, Joseph Levenson Prize – Pre-1900 China, 2023',
        titleCN: '>2023 年约瑟夫 · 列文森荣誉奖（中国 - 1900 年前）',
      },
    ],
    authorMeetsCritics: {
      title: '*Philosophy East and West*, April issue, 2023',
      titleCN: '《东西方哲学》(*Philosophy East and West*) 2023 年 4 月刊',
      critics: [
        // {
        //   desc: '[Loy Hui-chieh](https://www.usp.nus.edu.sg/about/people/loy-hui-chieh/): Introduction and Mozi',
        //   descCN: '[黎辉杰 (Loy Hui-chieh)](https://www.usp.nus.edu.sg/about/people/loy-hui-chieh/): 前言、墨子'
        // },
        // {
        //   desc: '[Hagop Sarkissian](https://www.gc.cuny.edu/people/hagop-sarkissian): Confucius ',
        //   descCN: '[Hagop Sarkissian](https://www.gc.cuny.edu/people/hagop-sarkissian): 孔子'
        // },
        // {
        //   desc: '[Sungmoon Kim](https://scholars.cityu.edu.hk/en/persons/sungmoon-kim(10a18ccf-ef32-4a53-a8dc-f4d7a8b0b684).html): Mencius, Xunzi',
        //   descCN: '[金圣文 (Sungmoon Kim)](https://scholars.cityu.edu.hk/en/persons/sungmoon-kim(10a18ccf-ef32-4a53-a8dc-f4d7a8b0b684).html): 孟子、荀子'
        // },
        // {
        //   desc: '[Yuan Ai](https://www.phil.tsinghua.edu.cn/info/1016/1964.htm): Laozi ',
        //   descCN: '[袁艾 (Yuan Ai)](https://www.phil.tsinghua.edu.cn/info/1016/1964.htm): 老子'
        // },
        // {
        //   desc: '[Yuri Pines](https://en.asia.huji.ac.il/people/yuri-pines): *Fajia* (Legalists)',
        //   descCN: '[尤锐 (Yuri Pines)](https://en.asia.huji.ac.il/people/yuri-pines): 法家'
        // },
        // {
        //   desc: '[Paul J. D’Ambrosio](http://www.philo.ecnu.edu.cn/23/d7/c655a271319/page.htm): Zhuangzi',
        //   descCN: '[德安博 (Paul J. D’Ambrosio)](http://www.philo.ecnu.edu.cn/23/d7/c655a271319/page.htm): 庄子'
        // },
        // {
        //   desc: `Tao Jiang: Author's response`,
        //   descCN: '蒋韬: 作者的回应'
        // }
        {
          desc: 'Loy Hui-chieh: Introduction and Mozi',
          descCN: '黎辉杰 (Loy Hui-chieh): 前言、墨子',
        },
        {
          desc: 'Hagop Sarkissian: Confucius ',
          descCN: 'Hagop Sarkissian: 孔子',
        },
        {
          desc: 'Sungmoon Kim: Mencius, Xunzi',
          descCN: '金圣文 (Sungmoon Kim): 孟子、荀子',
        },
        {
          desc: 'Yuan Ai: Laozi ',
          descCN: '袁艾 (Yuan Ai): 老子',
        },
        {
          desc: 'Yuri Pines: *Fajia* (Legalists)',
          descCN: '尤锐 (Yuri Pines): 法家',
        },
        {
          desc: 'Paul J. D’Ambrosio: Zhuangzi',
          descCN: '德安博 (Paul J. D’Ambrosio): 庄子',
        },
        {
          desc: `Tao Jiang: Author's response`,
          descCN: '蒋韬: 作者的回应',
        },
      ],
    },
    bookEvents: [
      {
        name: 'Book Panel',
        nameCN: '新书讨论会',
        host: 'American Philosophical Association Central Division',
        hostCN: '美国哲学协会中部分会',
        location: 'Denver, CO',
        locationCN: '丹佛，科罗拉多州',
        date: '02/24/2023',
        dateCN: '2023 年 2 月 24 日',
      },
      {
        name: 'Book Roundtable',
        nameCN: '新书圆桌会',
        host: 'American Academy of Religion',
        hostCN: '美国宗教学会',
        location: 'Denver, CO',
        locationCN: '丹佛，科罗拉多州',
        date: '11/21/2022',
        dateCN: '2022 年 11 月 21 日',
      },
      {
        name: 'Book Symposium',
        nameCN: '新书研讨会',
        host: 'City University of Hong Kong',
        hostCN: '香港城市大学',
        location: 'Hong Kong',
        locationCN: '香港',
        date: '02/04/2022',
        dateCN: '2022 年 2 月 4 日',
      },
    ],
    bookIntroduction: [
      `This book, *Origins of Moral-Political Philosophy in Early China: Contestation of Humaneness, Justice, and Personal Freedom*, is a systematic presentation of the intellectual projects at the origins of moral-political philosophy in early China. The foundational period in Chinese philosophy, from the time of Confucius (551-479 BCE) to the establishment of the first unified imperial dynasty in 221 BCE, has always been considered the single most creative and vibrant chapter in Chinese intellectual history. Works attributed to Confucius, Mozi, Mencius, Laozi, Shang Yang, Shen Dao, Zhuangzi, Xunzi, Han Feizi and many others represent the origins of moral and political thought in China. As testimony to their enduring lure, in recent decades many Chinese intellectuals, and even leading politicians, have turned to those classics, especially Confucian texts, for alternative or complementary sources of moral authority and political legitimacy.`,
      `I make three key points in retelling this founding narrative of Chinese moral-political philosophy. First, the central intellectual challenge during the Chinese classical period was how to negotiate the relationships between the personal, the familial, and the political domains (sometimes also characterized as the relationship between the private and the public) when philosophers were reimagining and reconceptualizing a new sociopolitical order, due to the collapse of the old order. Consequently, philosophers offered a dazzling array of competing visions for that newly imagined order. Second, the competing visions can be characterized as a contestation between impartialist justice and partialist humaneness as the guiding norms of the newly imagined moral-political order, with the Confucians, the Mohists, the Laoists, and the so-called *fajia* (Legalist) thinkers being the major participants, constituting the mainstream intellectual project during this foundational period of Chinese philosophy. Third, Zhuangzi and the Zhuangists were the outliers of the mainstream moral-political debate during this period who rejected the very parameter of humaneness versus justice in the mainstream debate. Zhuangzi and the Zhuangists were a lone voice advocating personal freedom. For the Zhuangists, the mainstream debate was intellectually banal, morally misguided, and politically dangerous. All such efforts took place within the context of an evolving understanding of Heaven and its relationship with the humans.`,
      `This new narrative aims at offering an alternative paradigm to interpret the unique configuration of classical Chinese philosophical landscape and helping to chart a new course in understanding the central motivating issues underlying the Chinese moral-political debate at its very inception. The book has incorporated scholarship on classical Chinese philosophy of the last several decades, providing a new overarching framework to recast the narrative of this formative period of Chinese philosophy. It is philosophically schematic and historically informed.`,
    ],
    bookIntroductionCN: [
      `《中国先秦道德政治哲学的起源：仁爱、正义与自由之论辩》是对先秦道德、政治哲学起源的系统诠释。中国哲学的奠基时期，即从孔子时代（公元前 551-479 年）到公元前 221 年秦朝的建立，一直被认为是中国思想史上最具创造性和活力的一个篇章。孔子、墨子、孟子、老子、商鞅、慎到、庄子、荀子、韩非子等人的作品代表了中国道德和政治思想的起源。近年来，许多中国知识分子以及政要都援引这些经典，尤其是儒家经典，足以证明这些经典的持久魅力。`,
      `本书在阐述中国道德、政治哲学的起源时提出了三个主要论点。首先，中国先秦时期的核心挑战是如何协调个人、家庭和政治领域之间的关系（有时也被描述为公私之间的关系）。旧秩序日渐崩溃，哲学家们都致力于重新想象与建构一个新的道德政治秩序，为这个新秩序构想出了一系列令人叹为观止的方案。第二，这些构想的方案可以被描述为仁爱与正义之间的对弈，以作为道德政治秩序的指导准则。儒家、墨家、老子和法家都是这一哲学论争的主导者，其论争的话题与思想的范式就是中国哲学奠基时期的主流思潮。第三，庄子及其追随者是这一主流思潮的局外人，他们拒绝了以仁爱和正义为主题的主流思潮。庄子是主张个人自由的孤独声音。他认为主流思想知识平庸、道德错乱，加剧了时局的动荡。有趣的是，所有这些哲学思辨都是在对天及其天人关系的理解不断发展变化的语境下展开的。`,
      `本书旨在提供一种新的范式来解释中国先秦哲学的主要议题与内在动力，并为理解推动中国道德、政治思想辩论中的核心问题提供新的思路。本书吸收了过去几十年来关于中国先秦哲学的学术成果，提出了一个新的总体框架，以重塑中国哲学形成期的整体形象。本书在哲学议题上力求系统全面，在历史议题上追求有理有据。`,
    ],
    reviews: [
      {
        review: `>“Jiang ranges over the entire foundational period of Chinese philosophy with effortless erudition, unfailing intellectual sympathy, and, above all, a brilliantly economical conception that shines a uniquely revealing and integrating light on all the major figures and schools of thought. The result is that rare kind of book which has the potential to change the way Chinese philosophy is viewed and practiced, and has all the scholarly and philosophical attributes that should make it a classic in due course.”`,
        reviewCN: `>“蒋韬在该书中展现出渊博的学识以及对各个哲学家的同理心。最重要的是，他以一个极为精辟的理论架构，对所有主要人物和思想流派进行了独特的揭示和整合，涵盖了整个中国哲学的奠基时期。这是一本罕见的著作，有可能改变人们对中国哲学的看法以及研究中国哲学的方式。假以时日，该书的学术论证与哲学洞见都应该使其成为中国哲学的经典之作。”`,
        reviewer: `Jiwei Ci, University of Hong Kong`,
        reviewerCN: '慈继伟 (Jiwei Ci)，香港大学',
        magazine: false,
      },
      {
        review: `>“Tao JIANG in this hugely intelligent monograph provides his readers with an interpretive context twice. First, his project of rehearsing the story of the origins of Chinese moral-political philosophy is located within a state-of-the-art account of the politics of the Western academy and the best efforts of its Sinologists and philosophers to make sense of the complex textual narrative of pre-Qin China in all of its parts. Again, appealing to a cluster of seminal themes—humaneness, justice, and personal freedom—he recounts the way in which different philosophical voices advocated for their own disparate and competing models of structuring and construing personal, familial, and political relations within the overarching context of what are fundamentally different valorizations of the notion of Heaven.”`,
        reviewCN: `>“蒋韬在这本极具智慧的专著中为他的读者提供了两个语境。首先，在叙述中国道德、政治哲学的起源时，他涉及西方学术界在中国哲学问题上的政治论争，也充分汲取了西方汉学家和哲学家研究中国先秦文献的最新成果。其次，他以仁爱、正义和自由为主题，阐述了不同的哲学声音对 ‘天’ 的概念作出不同诠释，并在这一总体语境下探讨他们在建构与规范个人、家庭和政治关系时所进行的的思考以及不同范式之间的竞争。”`,
        reviewer: 'Roger T. Ames, Peking University',
        reviewerCN: '安乐哲 (Roger T. Ames)，北京大学',
        magazine: false,
      },
      {
        review: `>“Tao Jiang has provided a coherent and sweeping narrative of the development of moral and political philosophy in the classical period of Chinese philosophy. He integrates many plausible insights gleaned from sinology and philosophy to argue provocatively that the classical period can be understood in terms of a struggle to deal with conflicts between the values of humaneness (pertaining to the personal and familial realms) and of justice (pertaining to the political realm). This book is highly recommended both to specialists and to those with a more general interest in Chinese moral and political philosophy.”`,
        reviewCN: `>“蒋韬对先秦时期中国的道德和政治哲学的发展进行了全面、系统的阐述。他整合了汉学和哲学中的许多见解，极富创意地提出，先秦哲学的主流可以理解为处理仁爱（与个人和家庭有关）和正义（与政治有关）之间的冲突。我强烈推荐此书与研究中国哲学的专家以及对中国道德和政治哲学感兴趣的人。”`,
        reviewer: 'David Wong, Duke University',
        reviewerCN: '戴维・王国旌 (David Wong)，杜克大学',
        magazine: false,
      },
      {
        review: `>“A grand and well-argued history of early Chinese philosophy.”`,
        reviewCN: `>“视野宏大且论证精细的早期中国哲学史。”`,
        reviewer: 'Taisu Zhang, Yale Law School',
        reviewerCN: '张泰苏(Taisu Zhang)，耶鲁法学院',
        magazine: false,
      },
      {
        review: `>“In this very important book, Tao Jiang provides a dynamic model of the development of moral-political philosophy in early China (ca. 551–221 BCE), which embodies a new approach to thinking about freedom in complicated socio-political realities. It convincingly demonstrates that thinkers of early China are important for philosophical studies today not only because they cover the themes that remain fundamental in contemporary debates but also because their argumentations came out of intellectual exchanges that were no less robust than their ‘Western’ counterparts.”`,
        reviewCN: `>“在这本非常重要的书中，蒋韬为中国早期（约公元前551-221年）的道德政治哲学的发展提供了一个动态模型，提出了在复杂的社会政治现实中思考自由的新方法。该书令人信服地阐述了早期中国思想家们的思辨对今天的哲学研究仍然意义重大。这不仅是因为他们涵盖了在当代哲学辩论中仍具根本意义的主题，还因为他们严谨激烈的论辩与 ‘西方’ 哲学史相比也毫不逊色。”`,
        reviewer: '*British Journal for the History of Philosophy*',
        reviewerCN: '《英国哲学史》 (*British Journal for the History of Philosophy*)',
        magazine: true,
      },
      {
        review: `>“What kinds of social environments and political institutions will enhance our lives and foster human flourishing? And how do we interpret early Chinese insights on these topics? *Origins of Moral-Political Philosophy in Early China* addresses both questions, in the process offering an important account of the rich and complex early Chinese intellectual exchanges on the nature of government and political institutions, and of human relationships, moral life, and freedom.”`,
        reviewCN: `>“什么样的社会环境和政治体制会改善我们的生活，促进人类的繁荣？我们如何解释中国早期哲学家对这些主题的见解？《中国先秦道德政治哲学的起源》探讨了这两个问题。在此过程中，对早期中国关于政府和政治机构的性质以及人与人之间的关系、道德生活和自由等丰富而复杂的问题作出了重要的探讨。”`,
        reviewer: '*Journal of Confucian Philosophy and Culture*',
        reviewerCN: '《儒家哲学与文化》 (*Journal of Confucian Philosophy and Culture*)',
        magazine: true,
      },
      {
        review: `>“Tao Jiang's new magnum opus is the most welcome addition to the rapidly increasing number of studies of early Chinese thought. *Origins of Political-Moral Philosophy in Early China* offers inspiringly refreshing perspectives on the major texts associated with the so-called Hundred Schools of Thought. Rich in insights, lucidly written…, and based on thorough incorporation of secondary studies in English…, this monograph is a must-read for students and scholars of China's intellectual history.”`,
        reviewCN: `>“在研究中国早期思想的众多专著之中，蒋韬的新作尤其令人瞩目。这部《中国先秦政治道德哲学的起源》为先秦百家思想的主要文本提出了许多令人耳目一新的观点。 该书见解丰富、文笔清晰，并充分结合了大量英文文献中对相关议题的讨论，是研究中国思想史的必读之作。”`,
        reviewer: '*The Journal of Asian Studies*',
        reviewerCN: '《亚洲研究》 (*The Journal of Asian Studies*)',
        magazine: true,
      },
      {
        review: `>“A distinct feature of this book is synthesizing Sinological studies into its philosophical interpretation. The author acutely recognizes the regretful gap between Sinology and the study of Chinese philosophy. In this book, the comprehensive Sinological references and intimate engagements with contemporary scholarship (particularly in English) of Chinese philosophy are admirable.”`,
        reviewCN: `>“这部著作的独到之处在于将汉学研究的成果融入到了哲学诠释中。作者敏锐地认识到汉学与中哲研究之间的一道鸿沟。书中收录了详尽的汉学资料，也对当代中哲研究的文献（尤其是英文文献）进行了全面的探讨，令人叹服。”`,
        reviewer: '*Dao*',
        reviewerCN: '《道》 (*Dao*)',
        magazine: true,
      },
      {
        review: `>“Every single chapter of Tao Jiang’s *Origins of Moral-Political Philosophy in Early China* is steeped in exhaustive research and analysis and presents provocative and insightful challenges to common perspectives. It will, in my view, become a standard in the field, and its influence and pedigree will outpace the decade and a half that went into writing it. It is a must-read for serious students of early Chinese philosophy.”`,
        reviewCN: `>“蒋韬所著的《中国先秦道德政治哲学的起源》基于详尽的研究和分析，并对现下流行的学术观点提出了既有启发而又令人深思的挑战。 我认为本书将为这个领域建立起一个新的标准，对学术界将会有深远的影响。 这是一部研究中国早期哲学的必读之作。”`,
        reviewer: '*Theoria: A Journal of Social and Political Theory*',
        reviewerCN: '《理论：社会政治理论》 (*Theoria: A Journal of Social and Political Theory*)',
        magazine: true,
      },
      {
        review: `>"This monograph is one of the most ambitious works in recent years on early Chinese philosophy, a major contribution that offers new readings and fresh interpretations. … I believe it will soon become a must-read for scholars and students interested in classic Chinese philosophy, as its findings will exert impact on our current understanding of Confucianism, Daoism, Mohism and Legalism."`,
        reviewCN: `>“这部专著是近年来关于中国早期哲学最雄心勃勃的作品之一。这一重要贡献为先秦诸子提出了全新的解读。我相信该书将很快成为研究早期中国哲学的学者和学生的必读著作，并影响我们当前对儒家、道家、墨家和法家的理解。”`,
        reviewer: '*Journal of Chinese Philosophy*',
        reviewerCN: '《中国哲学》 (*Journal of Chinese Philosophy*)',
        magazine: true,
      },
      {
        review: `>"Jiang always succeeds in providing interesting examples to make his point. It will attract nonacademic but well-informed readers as well as intelligent undergraduates who love Chinese history. It should be read by graduate students who intend to become specialists in Chinese philosophy and pre-imperial China. More important, Origins of Moral-Political Philosophy in Early China promises to be a valuable resource for those teaching courses that cover to various degrees pre-imperial Chinese thought, politics, and culture at both undergraduate and graduate levels. Instructors teaching survey courses on China or East Asia can refer to the basic information and interesting anecdotes contained in the book to introduce essential ancient Chinese texts and their main ideas. In graduate seminars, students can be encouraged to think about how the book provides new perspectives on early Chinese thinkers ranging from Confucius to Han Fei."`,
        reviewCN: `>“蒋韬总是能够提供非常有趣的例子来表达他的观点。这将吸引非学术但见多识广的读者以及喜爱中国历史的本科生。那些想成为研究中国哲学和先秦中国史专家的研究生也应该阅读此书。更重要的是，《早期中国的道德政治哲学起源》有望成为讲授本科和研究生课程的宝贵资源，对中国先秦思想、政治和文化做不同程度的涵盖。讲授中国或东亚概论课程的教师可以参考本书中的基本信息和趣闻轶事来介绍中国古籍及其主要观点。在研究生研讨课中，可以鼓励学生思考这本书如何为从孔子到韩非等早期中国思想家提供了新的视角。”`,
        reviewer: '*H-Net*',
        reviewerCN: '*H-Net*',
        magazine: false,
      },
      {
        review: `>"Tao Jiang’s book presents an ambitious and sweeping survey of early Chinese thought that is in some ways a successor—but also a rival—to a handful of other classic English-language studies likewise offering ambitious and sweeping treatments of the subject, such as Benjamin Schwartz’s *The World of Thought in Ancient China* (Belknap Press, 1985) and Angus Graham’s *Disputers of the Tao* (Open Court, 1989)."`,
        reviewCN: `>“蒋韬的书对早期中国思想进行了大胆全面的探讨，可以说是几部经典英文研究的继承者，同时也是其竞争对手。那些经典文献同样对这一主题提供了大胆全面的处理，例如本杰明·施瓦茨的著作 《中国古代思想的世界》（1985 年）和安格斯·葛瑞汉的《论道者》（1989 年）。”`,
        reviewer: '*Notre Dame Philosophical Reviews*',
        reviewerCN: '《圣母大学哲学评论》',
        magazine: true,
      },
      {
        review: `>"It is difficult not to be impressed with his erudition. I repeatedly found myself marking interesting books and articles that I was previously unfamiliar with but wanted to read for myself after Jiang drew them to my attention. I can easily imagine Jiang’s book being the focus of a productive reading group with advanced students of Chinese philosophy and religion, for whom it could introduce a wide range of topics and approaches."`,
        reviewCN: `>“读者很难不对蒋韬的博学所折服。我常常发现自己在他提到的书籍和文章上做标记。这些书籍和文章以前我不熟悉，但是他引起了我的注意，于是想去读读。对中国哲学和宗教学感兴趣的学生完全可以组成阅读小组来阅读本书，以便接触到广泛的哲学主题和研究方法。”`,
        reviewer: '*MIND*',
        reviewerCN: '*MIND*',
        magazine: true,
      },
    ],
    // readLink: `/buddhism`,
    // readKey: `freud`
  },
  buddhism: {
    pageName: 'Buddhism and Psychology',
    seoTitle: `Contexts and Dialogue: Yogācāra Buddhism and Modern Psychology on the Subliminal Mind - Tao Jiang`,
    seoTitleCN: `语境与对话：佛教唯识宗与当代心理学之潜意识论 - 蒋韬`,
    seoDescription: `Contexts and Dialogue: Yogācāra Buddhism and Modern Psychology on the Subliminal Mind is a fascinating study of the subliminal mind across cultural boundaries. It proposes a new paradigm in comparative approach to ideas through contextualization and recontextualization.`,
    seoDescriptionCN: `语境与对话：佛教唯识宗与当代心理学之潜意识论`,
    title: `Contexts and Dialogue: Yogācāra Buddhism and Modern Psychology on the Subliminal Mind`,
    titleCN: `语境与对话：佛教唯识宗与当代心理学之潜意识论`,
    coverImageName: `buddhism-cover.jpg`,
    publisher: `University of Hawaii Press`,
    publisherCN: `夏威夷大学出版社`,
    bookLink: `https://a.co/d/1nYnDJI`,
    themeColor: 'rgb(236, 133, 19)',
    bookIntroduction: [
      `Are there Buddhist conceptions of the unconscious? If so, are they more Freudian, Jungian, or something else? If not, can Buddhist conceptions be reconciled with the Freudian, Jungian, or other models? These are some of the questions that have motivated modern scholarship to approach *ālayavijñāna*, the storehouse consciousness, formulated in Yogācāra Buddhism as a subliminal reservoir of tendencies, habits, and future possibilities.`,
      `Tao Jiang argues convincingly that such questions are inherently problematic because they frame their interpretations of the Buddhist notion largely in terms of responses to modern psychology. He proposes that, if we are to understand *ālayavijñāna* properly and compare it with the unconscious responsibly, we need to change the way the questions are posed so that *ālayavijñāna* and the unconscious can first be understood within their own contexts and then recontextualized within a dialogical setting. In so doing, certain paradigmatic assumptions embedded in the original frameworks of Buddhist and modern psychological theories are exposed. Jiang brings together Xuan Zang’s *ālayavijñāna* and Freud’s and Jung’s unconscious to focus on what the differences are in the thematic concerns of the three theories, why such differences exist in terms of their objectives, and how their methods of theorization contribute to these differences.`,
      `Contexts and Dialogue puts forth a fascinating, erudite, and carefully argued presentation of the subliminal mind. It proposes a new paradigm in comparative philosophy that examines the what, why, and how in navigating the similarities and differences of philosophical systems through contextualization and recontextualization.`,
    ],
    bookIntroductionCN: [
      `佛教中是否有无意识概念？如果有，是接近弗洛伊德、荣格、或者别的范式？如果没有，佛教中类似的概念能否与弗洛伊德、荣格或其他范式相协调？这些都是当代学者在研究阿赖耶识过程中提出的一些问题。阿赖耶识，即藏识，在佛教唯识宗（瑜伽行派）中被制定义为藏储各种心理活动、习惯以及未来可能性的潜意识库。`,
      `但在蒋韬看来，以这样的问题去研究阿赖耶识是有问题的，因为这些问题对佛教概念的解释都是以现代心理学的框架作为出发点。他提出，如果我们要正确理解阿赖耶识，并负责任地将其与当代心理学中的无意识进行比较，就需要改变提问题的方式，使阿赖耶识和无意识首先能在其自身缘起的原生语境下得以分析理解，然后再从对话的语境下被重新解读。这样做，佛教和现代心理学理论的原始框架中自带的某些范式与假设就可以同时被凸显出来。蒋韬将玄奘的阿赖耶识和弗洛伊德及荣格的无意识结合起来，集中讨论了这三种理论体系不同的关注点，理论目标的差别以及迥异的理论化方法。以此来深度探讨三种潜意识论异同及其意义。`,
      `《语境与对话》对潜意识进行了一个生动、博学、精辟的介绍。本书为比较哲学也提出了一个新的研究范式，即通过原生语境和对比语境的方法来探讨哲学体系的相似性和差异性。`,
    ],
    reviews: [
      {
        review: `>“The wonderful sense of conclusion that Jiang brings to the reader is conveyed in the idea that there is much to be learned through interdisciplinary discussion. Jiang emphasizes that this discussion cannot take place at a shallow level, as very little new knowledge can be gained thereby. The point is made that we must learn to engage with different disciplines through what he calls ‘‘context and dialogue.’’ Overall, Jiang’s main argument for the importance of creating a new methodology for cross-disciplinary research in this way is presented very successfully.”`,
        reviewer: 'Philosophy East and West',
        reviewCN: `>“蒋韬将读者带进一个奇妙的终结点，使读者能体会到跨学科讨论的优越性。蒋韬强调，这种讨论不能在浅层次上进行，因为由此所获得的新知识非常少。他指出，我们必须学会通过他所说的“语境和对话”与不同的学科接触。总的来说，蒋教授以这种方式为跨学科研究创造一种新的方法论，其主要的论点表述得非常成功。”`,
        reviewerCN: '《东西方哲学》(*Philosophy East and West*)',
        magazine: true,
      },
      {
        review: `>“Jiang’s book succeeds on three levels: first, it offers an exceptionally clear introduction to Yogācāra Buddhism; second, it illuminates one very general and important difference between the models of mind in Buddhism and western psychology; and finally, it provides the right methodological cue for scholars engaged in comparative philosophical work. One leaves this book convinced that synthetic dialogue between Buddhism and modern psychology is possible, but that it will falter without adequate analysis of the paradigmatic differences that shape their respective projects.”`,
        reviewer: 'H-Net Reviews',
        reviewCN: `>“蒋的这本书在三个层面上取得了成功：首先，它对佛教瑜伽行派进行了清楚简明的介绍；其次，它阐明了佛教和西方心理学对意识的理解存在着非常重要的差异；最后，它为从事比较哲学研究的学者提供了正确的方法论。看完这本书时，人们相信佛教和现代心理学之间的有效对话是可能的，但如果不对他们各自的范式差异进行充分的分析，这一对话的成果就会不大。”`,
        reviewerCN: 'H-Net 书评 (H-Net Reviews)',
        magazine: false,
      },
      {
        review: `>“Jiang’s work … is a cautious reminder about the potential dangers of comparison that is helpful in the current academic climate, where comparative approaches are so popular. All who undertake comparative endeavors (even those who are not interested in Yogācāra Buddhism) should read Jiang’s book.”`,
        reviewer: 'Buddhist-Christian Studies',
        reviewCN: `>“蒋的书谨慎指出了比较研究方法中存在的潜在问题，这在当前比较研究流行的学术氛围中是很有意义的。所有从事比较研究的学者（即使是那些对佛教瑜伽行派不感兴趣的人）都应该将此书列入他们的阅读清单。”`,
        reviewerCN: '《佛教与基督教研究》(*Buddhist-Christian Studies*)',
        magazine: true,
      },
      {
        review: `>“Contexts and Dialogue rewards readers with useful and yet succinct introductions to the work of three seminal thinkers, and convincingly expands the methodological horizons of comparative philosophy.”`,
        reviewer: 'Journal of Chinese Philosophy',
        reviewCN: `>“《语境与对话》一书对三位开创性思想家的理论体系做了有用而简洁的介绍，并令人信服地扩大了比较哲学方法论的视野。”`,
        reviewerCN: '《中国哲学》(*Journal of Chinese Philosophy*)',
        magazine: true,
      },
      {
        review: `>“Jiang’s stimulating work will appeal to a wide audience. He has succeeded in balancing the needs of various readers along with methodological issues and in so doing stayed focused on his primary goals. Overall, the work is a great success, points to new possibilities for dialogue between these viewpoints specifically, and attempts at cross-cultural study more generally.”`,
        reviewer: 'Dao',
        reviewCN: `>“蒋韬的这一精彩著作将吸引广大的读者。他成功地平衡了不同读者的需求和方法论问题，并在这一过程中专注于他的主要目标。总言之，这部作品非常成功。它指出了这些观点之间具体对话中的可能性，也为更广泛的跨文化研究做出积极有效的尝试。”`,
        reviewerCN: '《道》(*Dao*)',
        magazine: true,
      },
    ],
    // readLink: `/buddhism`,
    // readKey: `freud`
    // chapters: [
    //     "Introduction",
    //     "Chapter 1: The Origin of the Concept of Ālayavijñāna",
    //     "Chapter 2: Ālayavijñāna in the Cheng Weishi Lun: A Buddhist Theory of the Subliminal Mind",
    //     "Chapter 3: The Unconscious: Freud and Jung",
    //     "Chapter 4: Three Paradigms of the Subliminal Mind: Xuan Zang, Freud, and Jung",
    //     "Chapter 5: Accessibility of the Subliminal Mind: Transcendence versus Immanence",
    //     "Conclusion: An Emerging New World as a New Context",
    //     "Notes and Index"
    // ]
  },

  freud: {
    pageName: 'Freud and China',
    seoTitle: 'The Reception and Rendition of Freud in China - Tao Jiang, Philip J. Ivanhoe',
    seoTitleCN: `弗洛伊德与中国（蒋韬、艾文贺合编）`,
    seoDescription:
      'The Reception and Rendition of Freud in China explores the history, reception, and use of Freud and his theories in China, and makes an original and substantial contribution to our understanding of the Chinese people and their culture as well as to our appreciation of western attempts to understand the people and culture of China.',
    seoDescriptionCN: `弗洛伊德与中国（蒋韬、艾文贺合编）`,
    title: `The Reception and Rendition of Freud in China: China’s Freudian Slip, edited by Tao Jiang and Philip J. Ivanhoe`,
    titleCN: `弗洛伊德与中国（蒋韬、艾文贺合编）`,
    coverImageName: `freud.jpg`,
    publisher: `Routledge`,
    publisherCN: `劳特里奇出版社`,
    bookLink: `https://a.co/d/6fCOxkL`,
    // bookCoverColor: 'rgb(11, 81, 120)',
    // themeColor: '#DC3023',
    themeColor: 'rgb(236, 133, 19)',
    bookIntroduction: [
      `Although Freud makes only occasional, brief references to China and Chinese culture in his works, for almost a hundred years many leading Chinese intellectuals have studied and appropriated various Freudian theories. However, whilst some features of Freud’s views have been warmly embraced from the start and appreciated for their various explanatory and therapeutic values, other aspects have been vigorously criticized as implausible or inapplicable to the Chinese context.`,
      `This book explores the history, reception, and use of Freud and his theories in China, and makes an original and substantial contribution to our understanding of the Chinese people and their culture as well as to our appreciation of western attempts to understand the people and culture of China. The essays are organised around three key areas of research. First, it examines the historical background concerning the China-Freud connection in the 20th century, before going on to use reconstructed Freudian theories in order to provide a modernist critique of Chinese culture. Finally, the book deploys traditional Chinese thought in order to challenge various aspects of the Freudian project. Both Freudianism’s universal appeal and its cultural particularity are in full display throughout the book. At the same time, the allure of Chinese cultural and literary expressions, both in terms of their commonality with other cultures and their distinctive characteristics, are also scrutinized.`,
      `This collection of essays will be welcomed by those interested in early modern and contemporary China, as well as the work and influence of Freud. It will also be of great interest to students and scholars of psychology, psychoanalysis, literature, philosophy, religion, and cultural studies more generally.`,
    ],
    bookIntroductionCN: [
      `尽管弗洛伊德在他的作品中只是偶尔简短地提到中国和中国文化，但近一百年来，许多中国的知识分子都研究并采用了弗洛伊德的各种理论。然而，尽管弗洛伊德思想的强大诠释力与治疗价值从一开始就受到中国知识界的欢迎，但其思想的科学价值也受到了怀疑，并时而被指责为不适用于中国文化与传统。`,
      `本书探讨了弗洛伊德及其理论在中国的历史、接受和运用情况，以便加深理解中国文化以及西方学界在这方面的研究。书中收集的文章围绕三个领域展开。首先，该书探讨了20世纪中国知识界与弗洛伊德思想之间关系的历史背景，然后再利用重新建构起来的弗洛伊德理论对中国文化进行了现代主义的批判。最后，本书利用中国传统哲学来挑战弗洛伊德思想的许多方面。弗洛伊德思想的普世性及其特殊性在全书中都得到了充分展示。同时，本书也通过研究中国文化、哲学以及文学表达的诱惑力来探索与其它文化的共性和独特性。`,
      `这本论文集将受到那些对古代、现代和当代中国以及对弗洛伊德的影响感兴趣的读者的欢迎。对心理学、精神分析、文学、哲学、宗教和从事文化研究的学者也会有很大兴趣。`,
    ],
    // readLink: `/freud`,
    // readKey: `buddhism`
  },

  papers: {
    seoTitle: 'Papers - Tao Jiang',
    seoTitleCN: '论文 - 蒋韬',
    seoDescription: 'Select papers by Tao Jiang on Buddhist philosophy, Chinese philosophy and comparative philosophy.',
    categories: ['Chinese Philosophy', 'Buddhist Philosophy', 'Cross-Cultural Philosophy'],
    categoriesCN: ['中国哲学', '佛教哲学', '比较哲学'],
    shortCategories: ['Chinese Phil.', 'Buddhist Phil.', 'Cross-Cultural Phil.'],
    shortCategoriesCN: ['中国哲学', '佛教哲学', '比较哲学'],
    // themeColor: 'rgb(0, 216, 208)',
    themeColor: 'rgb(236, 133, 19)',
    items: [
      {
        title: `"Beyond Dust and Grime," in *Aeon* Magazine, 2022`,
        titleCN: `“游乎尘垢之外”，收录于 Aeon 电子杂志，2022`,
        tags: [0, 2],
        link: 'https://aeon.co/essays/zhuangzis-ancient-fable-about-the-personal-and-the-political',
      },
      {
        title: `"Detachment: A Trait-Reliabilist Virtue in Linji’s Chan Buddhism," in *Ernest Sosa Encountering Chinese Philosophy*, edited by Yong Huang, Bloomsbury, 2022`,
        titleCN: `“超脱：临济禅思想中性格可靠性的德性认识论”，收录于《索萨与中国哲学》，黄勇（编），布鲁姆斯伯里出版社，2022`,
        tags: [0, 1, 2],
        pathname: 'detachment_a_trait_reliabilist_virtue_in_Linji_s_chan_Buddhism.pdf',
      },
      {
        title: `"Moral Therapy and the Imperative of Empathy: Mencius Encountering Slote," in *Michael Slote Encountering Chinese Philosophy*, edited by Yong Huang, Bloomsbury, 2020`,
        titleCN: `“道德治疗与同理心：孟子和斯洛特的比较”，收录于《斯洛特与中国哲学》，黄勇（编），布鲁姆斯伯里出版社，2020`,
        tags: [0, 2],
        pathname: 'moral_therapy_and_the_imperative_of_empathy-mencius_encountering_slote.pdf',
      },
      {
        title: `"Ambivalence of Family and Disunity of Virtues in Mencius’ Political Philosophy," in *Journal of Confucian Philosophy and Culture* 33, 2020`,
        titleCN: `“孟子政治哲学中家庭观的矛盾以及德性的冲突”,《儒家哲学与文化》33，2020`,
        tags: 0,
        pathname: 'Ambivalence_of_Family_and_Disunity_of_Virtues_in_Mencius_Political_Philosophy.pdf',
      },
      {
        title: `"Character Is the Way: The Path to Spiritual Freedom in the *Linji Lu*" in *Dao Companion to Chinese Buddhist Philosophy*, edited by Youru Wang and Sandra Wawrytko, Springer, 2018`,
        titleCN: `“性格是道：《临济录》中的精神自由之路”，收录于DAO丛书《中国佛教哲学篇》，王佑如、Sandra Wawrytko（合编），施普林格出版社，2018`,
        tags: [0, 1],
        pathname: 'Character_is_the_Way_Path_to_Spiritual_Freedom_in_the_Linji_Lu.pdf',
      },
      {
        title: `"Oneness and its Discontent: Contesting Ren in Classical Chinese Philosophy," in *The Oneness Hypothesis: Beyond the Boundary of Self*, edited by Philip J. Ivanhoe et. al., Columbia University Press, 2018`,
        titleCN: `“一体性及其缺憾：中国先秦哲学中对‘仁’的争论”，载于《一体性假说：跨越自我的界限》, 艾文贺等（合编），哥伦比亚大学出版社，2018`,
        tags: 0,
        pathname: 'Contesting_Ren_in_Classical_Chinese_Philosophy.pdf',
      },
      {
        title: `"The Problem of Authorship and the Project of Chinese Philosophy: ZHUANG Zhou and the Zhuangzi between Sinology and Philosophy in Western Academy," *Dao: A Journal of Comparative Philosophy* 15.1, 2016`,
        titleCN: `“作者的身份与中国哲学的研究：西方汉学与哲学对庄周和《庄子》的论争”，《道》15.1， 2016`,
        tags: 0,
        pathname: 'Problem_of_Authorship.pdf',
      },
      {
        title: `"Incommensurability of Two Conceptions of Reality: Dependent Origination and Emptiness in Nāgārjuna’s MMK," *Philosophy East and West* 64.1, 2014`,
        titleCN: `“两种真实理念的不兼容性：龙树《中观论》里的缘起与性空”，《东西方哲学》64.1，2014`,
        tags: 1,
        pathname: 'Incommensurability_of_Two_Conceptions_of_Reality.pdf',
      },
      {
        title: `"Isaiah Berlin’s Challenge to the Zhuangzian Freedom," *Journal of Chinese Philosophy* 2012`,
        titleCN: `“以赛亚・柏林对庄子逍遥观的挑战”，《中国哲学》，2012`,
        tags: [0, 2],
        pathname: 'Zhuangzi_and_Berlin_on_freedom_JCP.pdf',
      },
      {
        title: `"Two Notions of Freedom in Classical Chinese Thought: The Concept of *Hua* 化 in the *Zhuangzi* and the *Xunzi*," *Dao: A Journal of Comparative Philosophy* 10, 2011`,
        titleCN: `“中国先秦思想中的两种自由观：《庄子》和《荀子》中的化”，《道》 10，2011`,
        tags: [0],
        pathname: 'Two_notions_of_freedom-Dao.pdf',
      },
      {
        title: `"Linji and William James on Mortality: Two Visions of Pragmatism," in *Mortality in Traditional China*, edited by Amy Olberding and Philip J. Ivanhoe. SUNY, 2011`,
        titleCN: `“临济与威廉・詹姆斯论死亡：两种实证主义的观点”，《传统中国的死亡观》，Amy Olberding、艾文贺（合编），纽约州立大学出版社，2011`,
        tags: [0, 1, 2],
        pathname: 'Linji_and_William_James_on_Mortality.pdf',
      },
      {
        title: `"The Dilemma of Skillful Means in Buddhist Pedagogy: Desire and Educations in the Lotus Sūtra," in *Educations and Their Purposes: A Philosophical Dialogue among Cultures*, edited by Roger Ames and Peter Hershock, University of Hawaii Press, 2007`,
        titleCN: `“佛教教学中方便法门的困境：《莲花经》中的欲望和教育”，《教育及其目的：文化间的哲学对话》，安乐哲、Peter Hershock（合编），夏威夷大学出版社，2007`,
        tags: 1,
        pathname: 'Dilemma_of_Skillful_Means_in_Buddhist_Pedagogy.pdf',
      },
      {
        title: `"Intimate Authority: The Rule of Ritual in Classical Confucian Political Discourse," Chapter Two in *Confucian Cultures of Authority in Practice: China*, edited by Roger Ames and Peter Hershock. State University of New York Press, 2006`,
        titleCN: `“亲密的权威：先秦儒家政治理念中的礼治”，《儒家权威文化的实践：中国》，安乐哲、Peter Hershock（合编），纽约州立大学出版社，2006`,
        tags: 0,
        pathname: 'Intimate_Authority.pdf',
      },
      {
        title: `"*Ālayavijñāna* and the Problematic of Continuity in the Cheng Weishi Lun," *Journal of Indian Philosophy* 33:3, 2005`,
        titleCN: `“《成唯识论》中的阿赖耶识与连续性的问题”，《印度哲学》33，2005`,
        tags: 1,
        pathname: 'Alayavijnana_and_continuity_JIP.pdf',
      },
      {
        title: `"Accessibility of the Subliminal Mind: Transcendence vs. Immanence," *Continental Philosophy Review* 38:3-4, 2005`,
        titleCN: `“潜意识的可达性：超越与内在”，《欧陆哲学评论》38，2005`,
        tags: [1, 2],
        pathname: 'Accessibility_of_the_subliminal_mind.pdf',
      },
      {
        title: `"The Problematic of Continuity: Nishida Kitarō and Aristotle," *Philosophy East & West* 55:3, 2005`,
        titleCN: `“连续性的问题：西田喜多郎与亚里士多德”，《东西方哲学》55，2005`,
        tags: 2,
        pathname: 'Nishida_and_Aristotle_on_continuity_PEW.pdf',
      },
      {
        title: `"The Storehouse Consciousness and the Unconscious: A Comparative Study of Xuan Zang and Freud on the Subliminal Mind," *Journal of the American Academy of Religion* 72:1, 2004`,
        titleCN: `“藏识与无意识：玄奘与弗洛伊德的对比研究”，《美国宗教协会杂志》72，2004`,
        tags: [1, 2],
        pathname: 'Alayavijnana_and_unconscious_JAAR.pdf',
      },
      {
        title: `"The Role of History in Chan/Zen Enlightenment," *Dao: A Journal of Comparative Philosophy* IV.1, 2004`,
        titleCN: `“历史在禅悟中的角色”，《道》IV.1，2004`,
        tags: [0, 1],
        pathname: 'Role_of_History_in_Chan_Zen_Enlightenment.pdf',
      },
      {
        title: `"A Buddhist Scheme in Engaging Modern Science: The Case of Taixu (1890-1947)," *Journal of Chinese Philosophy* 29:4, 2002`,
        titleCN: `“佛教与现代科学：太虚大师（1890-1947）研究，《中国哲学》29，2002`,
        tags: [0, 1],
        pathname: 'Taixu_and_modern_science_JCP.pdf',
      },
      {
        title: `"The Problematic of Whole/Part and the Horizon of the Enlightened in Huayan Buddhism," *Journal of Chinese Philosophy* 28:4, 2001`,
        titleCN: `“佛教华严宗对整体和部分的关系与悟境的讨论”，《中国哲学》28，2001.`,
        tags: [0, 1],
        pathname: 'Whole-part_in_Huayan_JCP.pdf',
      },
    ],
  },

  events: {
    seoTitle: `Events - Tao Jiang`,
    seoTitleCN: `学术活动 - 蒋韬`,
    seoDescription: 'Notable public events featuring Tao Jiang',
    items: [
      {
        location: 'Podcast: New Books Network',
        subject:
          'Interviewed by New Books Network on my book Origins of Moral-Political Philosophy in Early China, March 24, 2022',
        introduction: `[New Books Network](https://newbooksnetwork.com/origins-of-moral-political-philosophy-in-early-china)`,
        locationCN: '播客: New Books Network',
        subjectCN: `播客采访：中国先秦道德政治哲学的起源，2022年3月24日`,
        introductionCN: `[播客链接](https://newbooksnetwork.com/origins-of-moral-political-philosophy-in-early-china)`,
      },
      {
        location: 'Gettysburg College, Pennsylvania',
        subject:
          '"Personal Freedom and the Good Life in the Zhuangzi: An Early Daoist Vision," Mahan Lecture, Gettysburg College, November 15, 2021',
        introduction: null,
        locationCN: '葛底斯堡学院，宾夕法尼亚，美国',
        subjectCN: `“庄子人生思想中的个人自由，” 马翰系列讲座， 葛底斯堡学院， 2021年11月15日`,
        introductionCN: null,
      },
      {
        location: 'Calgary, Alberta, Canada',
        subject:
          '"Zhuangzi and the Tragedy of Personal Freedom in Chinese History," Numata Lecture, University of Calgary, October 29, 2021',
        introduction: null,
        locationCN: '卡尔加里, 艾伯塔省， 加拿大',
        subjectCN: `“庄子与中国历史上自由思想的悲剧，” 沼田系列讲座， 加拿大卡尔加里大学， 2021年10月29日`,
        introductionCN: null,
      },
      {
        location: 'Rutgers University, New Brunswick, NJ',
        subject: 'Chinese Philosophy Workshop',
        introduction: `In order to promote a better understanding of Chinese philosophy, Tao Jiang launched the Rutgers Workshop on Chinese Philosophy (RWCP) in 2012 with Stephen Angle (Wesleyan) and Ruth Chang (Rutgers) (Dean Zimmerman took over from Ruth Chang as a co-director in 2018 when Chang moved to Oxford). RWCP is designed to build a bridge between Chinese philosophy and Western analytic philosophy, promoting critical engagement and constructive dialogue between the two sides, with the hope of bringing the study of Chinese philosophy into the mainstream of philosophical discourse within the Western academy. It is run every other spring.`,
        locationCN: '罗格斯大学，新布朗维斯克校区，新泽西，美国',
        subjectCN: `中国哲学研讨会`,
        introductionCN: `为了促进西方学术界对中国哲学的更好理解，蒋韬与安靖如（Stephen Angle）和 Ruth Chang 在 2012 年成立了罗格斯中国哲学研讨会（RWCP）（2018 年 Chang 去牛津大学任教后，其位置由 Dean Zimmerman 接替）。RWCP 旨在为中国哲学和西方分析哲学之间搭建一座桥梁，促进双方建设性的对话，希望以此促进中国哲学融入西方学术界的主流。RWCP 每两年举办一次。`,
      },
      {
        location: 'Zoom Webinar',
        subject: `A Conversation on China with Ian Johnson, a Pulitzer Prize Winning Journalist`,
        introduction: `In a wide-ranging conversation on September 25, 2020, facilitated by Tao Jiang (RCCS director, Religion Department) and Tanja Sargent (Graduate School of Education), Ian Johnson, a Pulitzer-Prize winner and one of the most prominent Western journalists on China, talked about the many facets of his life and experience as a journalist in China over the 20 years he spent there.`,
        locationCN: 'Zoom 视频网络',
        subjectCN: `与普利策奖获得者伊恩・约翰逊谈中国经历`,
        introductionCN: `2020 年 9 月 25 日，蒋韬和罗格斯大学教育学院 Tanja Sargent 与伊恩・约翰逊 （普利策奖获得者、西方知名的驻华记者）进行了一场 90 分钟的深度讨论。约翰逊回顾了他在中国20年的工作和生活经历。`,
        youtubeId: 'dV6Sc1OHibY',
      },
      {
        location: 'Mt. Jiuhua, China',
        subject: 'Interview with Hong Kong iFeng News (鳳凰網)',
        introduction: `On July 21, 2019, Tao Jiang was interviewed in Mt. Jiuhua by iFeng News 鳳凰網, a popular Chinese language news channel headquartered in Hong Kong. The conversation focused on conceptions of freedom and their place in Chinese politics prior to the modern period. The content is loosely based on Jiang's article “[Isaiah Berlin‘s Challenge to the Zhuangzian Freedom](https://raw.github.com/biyunwu/academic-web/master/src/data/papers/Zhuangzi_and_Berlin_on_freedom_JCP.pdf),” published in *Journal of Chinese Philosophy* in 2012. [Here](https://guoxue.ifeng.com/c/7oXIeFmDwky) is the interview and [here](https://mp.weixin.qq.com/s/elyJ6CIEZBhHxU6Y81Dhbg) is an illustrated version of the interview. He was in Mt. Jiuhua to participate in a forum on Chan Buddhism and Chinese Culture, which received extensive coverages from [iFeng News](https://guoxue.ifeng.com/c/7oYxlP7MTdw).`,
        locationCN: '九华山，安徽，中国',
        subjectCN: `凤凰网采访`,
        introductionCN: `2019 年 7 月 21 日，蒋韬在九华山参加一个禅宗与中国文化的论坛时，接受了凤凰网的采访。采访的重点是自由的概念及其在近代以前的中国政治中的地位。内容大致基于蒋韬于 2012 年发表在《中国哲学》(*Journal of Chinese Philosophy*)上的文章[《以赛亚・柏林对庄子逍遥观的挑战》](https://raw.github.com/biyunwu/academic-web/master/src/data/papers/Zhuangzi_and_Berlin_on_freedom_JCP.pdf)。凤凰网对禅宗论坛和此次采访都进行了详细的报道。阅读[文字报道](https://guoxue.ifeng.com/c/7oXIeFmDwky)或浏览[插图新闻](https://mp.weixin.qq.com/s/elyJ6CIEZBhHxU6Y81Dhbg)。`,
      },
      {
        location: 'China Institute, New York, NY',
        subject: `Religion and the Rebuilding of China's Moral Foundations`,
        introduction: `On June 7, 2018, Tao Jiang participated in [a panel discussion at the China Institute](https://rccs.rutgers.edu/news/166-tao-jiang-participated-in-a-panel-discussion-at-the-china-institute-in-nyc) on “Religion and the Rebuilding of China's Moral Foundations,” with freelance writer Ian Johnson (Pulitzer-prize winner and the author of *The Souls of China: The Return of Religion after Mao*), moderated by Los Angeles Times journalist Barbara Demick (former LA Times Beijing and Seoul bureau chief and the author of *Nothing to Envy: Ordinary Lives in North Korea*). The event received an extensive [coverage in the Sing Tao Daily 星島日報](https://www.singtaousa.com/ny/436-紐約/792717-希望用傳統價值觀重建信仰體系+中國對宗教包容度有明顯提高/), a newspaper widely circulated among the Chinese community in North America.`,
        locationCN: '华美协会，纽约，美国',
        subjectCN: `宗教与中国道德基础的重建`,
        introductionCN: `2018 年 6 月 7 日，蒋韬在纽约中国研究所与自由撰稿人伊恩・约翰逊（普利策奖得主，《中国的灵魂》的作者）进行了一场有关“宗教与中国道德基础的重建”的对话。由《洛杉矶时报》记者芭芭拉・德米克（前《洛杉矶时报》北京和首尔分社社长，《无可羡慕：北韩普通人的生活》的作者）主持。[《星岛日报》](https://www.singtaousa.com/ny/436-紐約/792717-希望用傳統價值觀重建信仰體系+中國對宗教包容度有明顯提高/)对此次活动进行了广泛报道，该报在北美的华人社区中广泛发行。`,
        youtubeId: 'IefIdc4ms0o',
      },
    ],
  },

  about: {
    seoTitle: `About - Tao Jiang`,
    seoTitleCN: `关于蒋韬`,
    seoDescription: `Detail info about Tao Jiang`,
    name: 'Tao Jiang',
    nameCN: '蒋韬',
    details: [
      `I am Professor of Religion and Philosophy, with joint appointments in the [Department of Religion](https://religion.rutgers.edu/tao-jiang) and the [Department of Philosophy](https://philosophy.rutgers.edu), at Rutgers University, New Brunswick, New Jersey, US. My areas of specializations include classical Chinese philosophy, Mahāyāna Buddhist philosophy, and cross-cultural philosophy. `,
      `I grew up in the ancient city of [Chengdu](https://www.nytimes.com/2016/03/06/t-magazine/chengdu-china-travel-guide.html) in China’s southwest, “where the pace is slow, the food is spicy and there’s always time for a good gossip over a cup of tea.” As a boy, I often dreamt of an adventurous life in a faraway place, which probably explains why I came all the way to America to pursue my dream. Although America is far enough from China, an academic life devoted to philosophical inquiry does not sound like much of an adventure. Except it *is* an adventure: a genuine philosophical inquiry is an adventure of the mind in which that very inquisitive mind is its own limit. My approach to philosophy is to treat it as a way to study particular forms of intellectual and moral culture and the people who produce it.`,
      `I am the author of [*Origins of Moral-Political Philosophy in Early China*](/origins_of_chinese_phil/) (Oxford University Press, 2021), [*Contexts and Dialogue: Yogācāra Buddhism and Modern Psychology on the Subliminal Mind*](/buddhism_and_psychology), and the co-editor (with Philip J. Ivanhoe) of [*The Reception and Rendition of Freud in China: China’s Freudian Slip*](/freud_and_china/). My [articles](/papers/) have appeared in leading Asian and comparative philosophy journals and several major anthologies. I have also been working on several other book projects, including a new interpretation of Linji's Chan/Zen Buddhist philosophy, Buddha Nature in medieval Chinese intellectual history, and a comparative project on free will. `,
      `I am director of the [Center for Chinese Studies](https://rccs.rutgers.edu) at Rutgers University. I served as chair of the [Department of Religion](https://religion.rutgers.edu) for 9 years. I am the founder and co-director of the [Rutgers Workshop on Chinese Philosophy](/events). I also co-chair the [Neo-Confucian Studies Seminar](https://universityseminars.columbia.edu/seminars/neo-confucian-studies/) at Columbia University. I serve on the editorial boards of *Dao*, *Journal of Chinese Philosophy*, *Confucian Philosophy and Culture*, and *Journal of Buddhist Philosophy*.`,
      `I am married. We enjoy hanging out in coffee shops, museums, parks, and watching movies. We play tennis from time to time and love going to the US Open Tennis Championships. We support all effort dedicated to the saving of the world's wildlife. These amazing videos [“Hungry polar bear surprises a seal”](https://www.youtube.com/watch?v=0mgnf6t9VEc), [“Elephant Calf Starves To Death Due To Drought”](https://www.youtube.com/watch?v=RqeC6XB94zs) from the [BBC Earth series](https://www.facebook.com/bbcearth) have opened up our eyes to the beauty, intelligence and misery of animal lives in the wilderness. Check them out, if you haven't, and your view about wildlife will never be the same again.`,
    ],
    detailsCN: [
      `我是美国新泽西州罗格斯大学宗教、哲学教授，任教于[宗教系](https://religion.rutgers.edu/tao-jiang)和[哲学系](https://philosophy.rutgers.edu)，主要从事中国先秦哲学、大乘佛教哲学和跨文化哲学的研究。`,
      `我在中国西南的古城成都长大，“那里的生活节奏缓慢，美食小吃麻辣爽口，而且人们总有时间边喝茶边闲聊”。小时候，我经常梦想着去一个遥远的地方，这也许就是我为什么万里迢迢来到美国。哲学研究听起来平淡无奇，但其实妙趣无穷：真正的哲学探索是一种心性的体验，在这里，可以与历史上的大德先贤进行深度的心灵交流，也能让自己自由地去探索哲学的大千世界。对我来说，哲学是一种研究知性和道德文化及其建构者的绝妙途径。`,
      `我作有[《中国先秦道德政治哲学的起源》](/cn/origins_of_chinese_phil/)（牛津大学出版社, 2021 年 9 月）和[《语境与对话：佛教瑜伽行派与现代心理学论潜意识》](/cn/buddhism_and_psychology/)；与艾文贺 (Philip J. Ivanhoe) 合编[《弗洛伊德与中国》](/cn/freud_and_china/)。目前还处于创作阶段的书包括对临济禅宗思想的新解、中国中世纪思想史中的佛性论以及对自由意志的比较研究。`,
      `我是罗格斯大学[中国研究中心](https://rccs.rutgers.edu)主任。曾任[宗教系](https://religion.rutgers.edu)系主任九年。我是罗格斯大学[中国哲学研讨会](/cn/events)的共同发起人与负责人，并共同主持哥伦比亚大学[新儒家研讨会](https://universityseminars.columbia.edu/seminars/neo-confucian-studies/)。我是《道》(*Dao*)、《中国哲学》(*Journal of Chinese Philosophy*)、《儒家哲学与文化》(*Journal of Confucian Philosophy and Culture*)与《佛教哲学》(*Journal of Buddhist Philosophy*) 等学术期刊的编委成员。`,
    ],
  },
};

export default data;
