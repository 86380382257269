import styled from 'styled-components';

export const LogoContainer = styled.a`
  //margin-bottom: -1em;
  color: ${({ theme }) => (theme === 'light' ? '#000' : '#ec8513')};
  & svg.logo {
    fill: ${({ theme }) => (theme === 'light' ? '#000' : '#fff')};
    height: ${({ height }) => (height ? height : '35px')};
  }
  margin-top: ${({ isHome }) => (isHome ? '0.5em' : '0')};
  vertical-align: middle;
  display: inline-block;
  &:hover {
    border-bottom: 1px solid transparent;
  }

  @media (max-width: 970px) {
    mix-blend-mode: ${({ theme }) => (theme === 'light' ? 'unset' : 'difference')};
    margin: 0 auto;
  }
`;
