import React from 'react';
import { Container } from 'components/common';
import NavbarLinks from '../NavbarLinks';
import { Wrapper } from './styles';
import Logo from '../Logo';

const Navbar = ({ lang }) => {
  return (
    <Wrapper as={Container}>
      <Logo lang={lang} isHome={true} />
      <NavbarLinks lang={lang} desktop />
    </Wrapper>
  );
};

export default Navbar;
