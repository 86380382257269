import React, {useContext} from 'react';
import {Wrapper} from './styles';
import {ThemeContext} from "../../../../providers/ThemeProvider";
// import language from "../../../../assets/icons/language2.svg";

const ToggleLang = ({lang}) => {
  const toggleLangFunc = () => {
    // const langStr = localStorage.getItem('lang');
    const {protocol, host, pathname} = window.location;

    // if (langStr === 'en') {
    //   localStorage.setItem('lang', 'cn');
    //   if (pathname.startsWith("/cn/")) return;
    //   // window.location.pathname = "/cn" + pathname;
    //   window.open(`${protocol}//${host}/cn${pathname}`, "_blank").focus();
    // } else {
    //   localStorage.setItem('lang', 'en');
    //   if (!pathname.startsWith("/cn/")) return;
    //   // slice(3) gets rid of "/cn" from "/cn/path..." to get the default path for English language
    //   // window.location.pathname = pathname.slice(3);
    //   window.open(`${protocol}//${host}${pathname.slice(3)}`, "_blank").focus();
    // }

    if (pathname.startsWith("/cn/")) {
      window.open(`${protocol}//${host}${pathname.slice(3)}`, "_blank").focus();
    } else {
      window.open(`${protocol}//${host}/cn${pathname}`, "_blank").focus();
    }
  }

  const {theme} = useContext(ThemeContext);
  const langText = lang === "en" ? "中文" : "English";

  return (
    <Wrapper type="button" onClick={() => toggleLangFunc()} theme={theme}>
      {/*<img src={language} alt={langText} style={{height: "20px"}}/>*/}
      <span title={lang === "en" ? "访问中文版网页" : "Visit this page in English"}>{langText}</span>
    </Wrapper>
  );
};

export default ToggleLang;
