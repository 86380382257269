import React, {useContext, useEffect, useState} from "react";
import Helmet from "react-helmet";
import {ThemeContext} from "providers/ThemeProvider";
import Thumbnail from "assets/thumbnail/thumbnail.png";
import {
  address,
  contact,
  defaultDescription,
  defaultTitle,
  foundingDate,
  legalName,
  logo,
  social,
  socialLinks,
  url,
} from "data/config";

export const Seo = ({
                      title = defaultTitle,
                      description = defaultDescription,
                      location = "",
                      lang = "en",
                    }) => {
  const structuredDataOrganization = `{ 
		"@context": "http://schema.org",
		"@type": "Organization",
		"legalName": "${legalName}",
		"url": "${url}",
		"logo": "${logo}",
		"foundingDate": "${foundingDate}",
		"founders": [{
			"@type": "Person",
			"name": "${legalName}"
		}],
		"contactPoint": [{
			"@type": "ContactPoint",
			"email": "${contact.email}",
			"telephone": "${contact.phone}",
			"contactType": "academic/admin contact"
		}],
		"address": {
			"@type": "PostalAddress",
			"addressLocality": "${address.city}",
			"addressRegion": "${address.region}",
			"addressCountry": "${address.country}",
			"postalCode": "${address.zipCode}"
		},
		"sameAs": [
			"${socialLinks.twitter}",
			"${socialLinks.google}",
			"${socialLinks.youtube}",
			"${socialLinks.linkedin}",
			"${socialLinks.instagram}",
			"${socialLinks.github}",
			"https://religion.rutgers.edu/graduate/graduate-faculty/892-tao-jiang-4",
			"https://rccs.rutgers.edu/people/director"
		]
  }`;

  const {theme} = useContext(ThemeContext);

  const [isHome, setIsHome] = useState(false);

  useEffect(() => {
    if (['/', '/cn/'].includes(window.location.pathname)) {
      setIsHome(true);
    } else {
      setIsHome(false);
    }
  }, []);

  return (
    <Helmet>
      <meta name="description" content={description}/>
      <meta name="image" content={Thumbnail}/>
      <meta property="og:url" content={`${url}${location}`}/>
      <meta property="og:type" content="website"/>
      <meta property="og:title" content={title}/>
      <meta property="og:description" content={description}/>
      <meta property="og:image" content={Thumbnail}/>
      <meta property="fb:app_id" content={social.facebook}/>
      <meta name="twitter:card" content="summary"/>
      <meta name="twitter:creator" content={socialLinks.twitter}/>
      <meta name="twitter:site" content={social.twitter}/>
      <meta name="twitter:title" content={title}/>
      <meta name="twitter:description" content={description}/>
      <meta name="twitter:image:src" content={Thumbnail}/>
      <meta name="apple-mobile-web-app-title" content="Tao Jiang"/>
      {/* homepage and other pages have different theme colors */}
      <meta name="theme-color" content={theme === 'light' ? isHome ? "#fff" : 'rgb(245, 247, 249)' : "#000"}
            data-react-helmet="true"/>
      <script type="application/ld+json">{structuredDataOrganization}</script>
      <link rel="publisher" href={socialLinks.google}/>
      <title>{title}</title>
      <html lang={lang === "en" ? "en" : "zh"} dir="ltr"/>
    </Helmet>
  );
};
