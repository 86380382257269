import styled from 'styled-components';

export const Wrapper = styled.a`
  color: ${({ theme }) => (theme === 'light' ? 'rgba(0,0,0,0.45)' : 'rgba(255,255,255,0.8)')};;
  &:hover {
      color: ${({ theme }) => (theme === 'light' ? 'rgba(0,0,0,0.84)' : 'rgb(255,255,255)')};
  }
  background: none;
  border: none;
  cursor: pointer;
  //transition: 0.3s all;

  &:focus {
    outline: none;
    transition: 0.3s all;
  }

  &:hover {
    text-decoration: none;
  }

  @media (max-width: 960px) {
    text-align: left;
  }

  img {
    margin-bottom: unset;
  }
`;
