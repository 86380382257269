import React, { useContext } from 'react';
import { Link } from 'gatsby';
import { ThemeContext } from 'providers/ThemeProvider';
import { LogoContainer } from './styles';
import logo from '../../../../assets/icons/logo.svg';

const Logo = ({ lang, height, isHome }) => {
  const { theme } = useContext(ThemeContext);

  return (
    <LogoContainer as={Link} to={lang === 'en' ? '/' : '/cn/'} theme={theme} height={height} isHome={isHome}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 174.6976 46.8146" className={'logo'}>
        <use xlinkHref={`${logo}#t`} />
        <use xlinkHref={`${logo}#a`} />
        <use xlinkHref={`${logo}#o`} />
        <use xlinkHref={`${logo}#J`} />
        <use xlinkHref={`${logo}#I`} />
        <use xlinkHref={`${logo}#A`} />
        <use xlinkHref={`${logo}#N`} />
        <use xlinkHref={`${logo}#G`} />
      </svg>
    </LogoContainer>
  );
};

export default Logo;
