import React from 'react';
import {Container} from 'components/common';
import {Details, Flex, Links, Wrapper} from './styles';
import social from './social.json';
import Logo from "../Header/Logo";

export const Footer = ({lang}) => {
  const isEn = lang === "en"
  return (
    <Wrapper>
      <Flex as={Container}>
        <Details>
          <p>
            {/*<Logo lang={lang} height={"25px"} /> {' '}*/}
            © 2018-{new Date().getFullYear()}{' '}
            <Logo lang={lang} height={"25px"} isHome={true}/>{' '}
            {isEn ? ". All rights are reserved. " : "保留所有权利。"} {' '}
            {isEn ? "Developed by " : "此网站由 "}
            Biyun Wu
            {isEn ? "." : " 开发。"}
          </p>
        </Details>
        <Links>
          {social.map(({id, name, link, icon, rel}) => (
            // eslint-disable-next-line react/jsx-no-target-blank
            <a className={"social_svg"} key={id} href={link} target="_blank" rel={rel || "noopener noreferrer"}
               aria-label={`follow me on ${name}`}>
              <img width="24" src={icon} alt={name}/>
            </a>
          ))}
        </Links>
      </Flex>
    </Wrapper>
  )
};
