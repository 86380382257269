import React, { useContext } from 'react';
import { ThemeContext } from 'providers/ThemeProvider';
import NavbarLinks from '../NavbarLinks';
import { Wrapper } from './styles';

const Sidebar = ({ lang, sidebar, toggle, notHomePage }) => {
  const { theme } = useContext(ThemeContext);
  
  return (
    <Wrapper active={sidebar} onClick={() => toggle(!sidebar)} theme={theme}>
      <NavbarLinks lang={lang} notHomePage={notHomePage}/>
    </Wrapper>
  )
};

export default Sidebar;
